import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const PartnershipsForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_ipEQprs49rS7h2Xkb9rMUOui', null, captchaRef)}
      id="partnerships-form"
    >
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="firstName"
        ng-model="formFormbucket.formData.firstName"
        ng-required="true"
        name="firstName"
        value=""
        type="text"
        required=""
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="lastName"
        ng-model="formFormbucket.formData.lastName"
        ng-required="true"
        name="lastName"
        value=""
        type="text"
        required=""
      />
      <h3>Business</h3>
      <input
        className="form-control"
        id="business"
        ng-model="formFormbucket.formData.business"
        name="business"
        ng-init="formFormbucket.formData.business = ''"
        value=""
        type="text"
      />
      <h3>Email address *</h3>
      <input
        className="form-control"
        id="email"
        ng-model="formFormbucket.formData.email"
        ng-required="true"
        name="email"
        value=""
        type="email"
        required=""
      />
      <h3>Phone</h3>
      <input
        className="form-control"
        id="phone"
        ng-model="formFormbucket.formData.phone"
        name="phone"
        ng-init="formFormbucket.formData.phone = ''"
        value=""
        type="tel"
      />
      <h3>Message *</h3>
      <textarea
        id="message"
        rows="9"
        cols="33"
        ng-model="formFormbucket.formData.message"
        ng-required="true"
        name="message"
        required=""
      />
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

PartnershipsForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

PartnershipsForm.defaultProps = {
  buttonText: null,
};
